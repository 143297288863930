import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getEventsList() {
        return apiClient.get('api/v1/cms-events-list')
    },
    getEvents() {
        return apiClient.get('api/v1/cms-events')
    },
    getEventDetail(guid) {
        return apiClient.get('api/v1/cmseventdetail/' + guid)
    },
    updateEvent(event) {
        return apiClient.post('api/v1/cms-event-update', event)
    },

    // RESOURCES: AKA Blogging
    getLastResourceID() {
        return apiClient.get('api/v1/cms/resource/latest')
    },
    getResources() {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.get('api/v1/cms/resources-available', {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    getResource(resourceID) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.get('api/v1/cms/resource/' + resourceID, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    updateResource(resource) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.post('api/v1/cms/resource-update', resource, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    addResource(resource) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.post('api/v1/cms/resource-add', resource, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    deleteResource(resourceID) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.get('api/v1/cms/resource-delete/' + resourceID, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    getBlogImages(blogID) {
        return apiClient.get('api/v1/cms/blog/images/' + blogID)
    },
    addBlogImages(images) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.post('api/v1/cms/blog/add-images', images, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    // Remove blog image from database table blog_images
    // Called when deleting existing images during editing of a blog post
    removeBlogImage(image) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.post('api/v1/cms/blog/remove-image', image, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    deleteImageFromFileSystem(image) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.post('api/v1/cms/blog/image/delete-fs', image, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    },
    deleteSingleImageFromFileSystem(imageFilename) {
        let user = JSON.parse(localStorage.getItem('user'));
        return apiClient.get('api/v1/cms/event/image/delete/' + imageFilename, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
    }
}

