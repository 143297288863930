import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('../views/Home.vue')
const Events = () => import('../views/Events.vue')
const EventDetail = () => import('../views/EventDetail.vue')

const SummerSessions = () => import('../views/SummerSessions.vue')
const SummerSessionDetail = () => import('../views/SummerSessionDetail.vue')

const AboutJacinta = () => import('../views/AboutJacinta.vue')
const Blog = () => import('../views/Blog.vue')
const BlogDetail = () => import('../views/BlogDetail.vue')
const IFL = () => import('../views/InnovationForLawyers.vue')
const TrainingPresentations = () => import('../views/TrainingPresentations.vue')
const TrainingRequest = () => import('../views/TrainingRequest.vue')
const Contact = () => import('../views/Contact.vue')

// CMS
const cmsIndex = () => import('../views/cms/Index.vue')
const cmsHome = () => import('../views/cms/Home.vue')
const cmsLogin = () => import('../views/cms/Login.vue')

// CMS TRAINER
const cmsTrainerList = () => import('../views/cms/trainers/List.vue')
const cmsTrainerCreate = () => import('../views/cms/trainers/Add.vue')
const cmsTrainerEdit = () => import('../views/cms/trainers/Edit.vue')
const cmsTrain = () => import('../views/Train.vue')

// CMS EVENT
const cmsEventList = () => import('../views/cms/events/EventsList.vue')
const cmsEventAdd = () => import('../views/cms/events/EventsAdd.vue')
const cmsEventEdit = () => import('../views/cms/events/EventEdit.vue')

// CMS BLOG
const cmsBlogList = () => import ('../views/cms/blog/list.vue')
const cmsBlogDetails = () => import ('../views/cms/blog/details.vue')
const cmsBlogEdit = () => import ('../views/cms/blog/BlogEdit.vue')
const cmsBlogAdd = () => import ('../views/cms/blog/BlogAdd.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/Events',
    name: 'Events',
    component: Events,
    meta: { requiresAuth: false }
  },
  {
    path: '/EventDetail',
    name: 'EventDetail',
    component: EventDetail,
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: '/AboutJacinta',
    name: 'About Jacinta',
    component: AboutJacinta,
    meta: { requiresAuth: false }
  },
  {
    path: '/Blog',
    name: 'Blog',
    component: Blog,
    meta: { requiresAuth: false }
  },
  {
    path: '/BlogDetail',
    name: 'BlogDetail',
    component: BlogDetail,
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: '/InnovationForLawyers',
    name: 'Innovation For Lawyers',
    component: IFL,
    meta: { requiresAuth: false }
  },
  {
    path: '/TrainingPresentations',
    name: 'Training Presentations',
    component: TrainingPresentations,
    meta: { requiresAuth: false }
  },
  {
    path: '/TrainingRequest',
    name: 'Training Request',
    component: TrainingRequest,
    meta: { requiresAuth: false }
  },
  {
    path: '/Train',
    name: 'Train',
    component: cmsTrain,
    meta: { requiresAuth: false }
  },
  {
    path: '/SummerSessions',
    name: 'Summer Sessions',
    component: SummerSessions,
    meta: { requiresAuth: false }
  },
  {
    path: '/SummerSessionDetail',
    name: 'Summer Session Detail',
    component: SummerSessionDetail,
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
    meta: { requiresAuth: false }
  },
  // CMS Links
  {
    path: '/cms/index',
    name: 'cmsIndex',
    component: cmsIndex,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms',
    name: 'cmsHome',
    component: cmsHome,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/event/list',
    name: 'cmsEventList',
    component: cmsEventList,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/event/add',
    name: 'cmsEventAdd',
    component: cmsEventAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/event/edit',
    name: 'cmsEventEdit',
    component: cmsEventEdit,
    meta: { requiresAuth: true },
    props: true
  },
  // BLOG
  {
    path: '/cms/blog/list',
    name: 'cmsBlogList',
    component: cmsBlogList,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/blog/details',
    name: 'cmsBlogDetails',
    component: cmsBlogDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/blog/edit',
    name: 'cmsBlogEdit',
    component: cmsBlogEdit,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/cms/blog/add',
    name: 'cmsBlogAdd',
    component: cmsBlogAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'cmsLogin',
    component: cmsLogin,
    meta: { requiresAuth: false }
  },
  // TRAINERS
  {
    path: '/cms/trainers/list',
    name: 'cmsTrainerList',
    component: cmsTrainerList,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/trainer/create',
    name: 'cmsTrainerCreate',
    component: cmsTrainerCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/cms/trainers/edit',
    name: 'cmsTrainerEdit',
    component: cmsTrainerEdit,
    meta: { requiresAuth: true }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  const loggedIn = await JSON.parse(localStorage.getItem('user'))
  if(to.name !== 'Login' && !loggedIn && to.matched.some(link => link.meta.requiresAuth)) {
    return '/login'
  } else if(!loggedIn && to.matched.some(link => link.meta.requiresAuth)) {
    return '/login'
  }

})

export default router
