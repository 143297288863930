import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Toast from 'primevue/toast'
import store from './store'
import Environment from '../src/components/Environment.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import '../src/assets/styles/css/print.css'
//import '../src/assets/styles/css/vanillatoasts.css'

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import '../src/assets/styles/css/styles.css'

createApp(App)
.use(store)
.use(PrimeVue)
.use(ToastService)
.use(ConfirmationService)
.use(router)
.use(VueReCaptcha, { siteKey: "6Lehkj8cAAAAAMF_Jk_xiR69BozNckcreNc259WZ"})
.component('Toast', Toast)
.component('Environment', Environment)
.mount('#app')
