import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        //'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
})

export default {   
    getTrainer(eventID) {
        return apiClient.get('api/v1/trainer-detail/' + eventID)
    },
    getTrainers() {
        return apiClient.get('api/v1/trainers')
    },
    addTrainer(trainer) {
        return apiClient.post('api/v1/createtrainer', trainer)
    },
    getTrainersForEvent(ids) {
        return apiClient.post('api/v1/trainer/resources/event', {id: ids})
    },
    
    // CMS CALLS TO TRAINING RESOURCES
    getTrainingResources() {
        return apiClient.get('api/v1/cms/trainer/resources')
    },
    getTrainingResource(tID) {
        return apiClient.get('api/v1/cms/trainer/resource/' + tID)
    },
    createTrainingResource(trainer) {
        return apiClient.post('api/v1/cms/trainer/resource/create', trainer)
    },
    updateTrainingResource(trainer) {
        return apiClient.post('api/v1/cms/trainer/resource/update', trainer)
    },
    deleteTrainingResource(trainer) {
        return apiClient.post('api/v1/cms/trainer/resource/delete', trainer)
    },
    deleteImageFromFileSystem(filename) {
        return apiClient.get('api/v1/cms/trainer/resource/image/delete/' + filename)
    }
}

