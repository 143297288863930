<template>
    <div class="grid">
      <div class="header">Environment:</div><div class="content">{{ environment }}</div>
      <div class="header">App Version:</div><div class="content">{{ version }}</div>
      <div class="header">View:</div><div class="content">{{ pageId }}</div>
      <div class="header">Browser:</div><div class="content">{{ browser }}</div>
      <div class="header">Engine:</div><div class="content">{{ engine }}</div>
      <div class="header">Platform:</div><div class="content">{{ platform }}</div>
      <div class="header">OS:</div><div class="content">{{ os }}</div>
      <div class="header">Timestamp:</div><div class="content">{{ timestamp }}</div>
    </div>
</template>

<script>
import moment from 'moment'
import Bowser from 'bowser'

export default {
  name: 'Environment',
  data() {
    return {
      environment: process.env.VUE_APP_ENVIRONMENT,
      version: process.env.VUE_APP_VERSION,
      pageId: this.$route.name,
      browser: '',
      engine: '',
      os: '',
      platform: '',
      timestamp: moment().format("DD-MMM-yyyy @ hh:mm:ss A [TimeZone:] Z")
    }
  },
  methods: {
    getBrowserInfo() {
      let detail = Bowser.getParser(window.navigator.userAgent)
      this.browser = `${detail.getBrowserName()} ${detail.getBrowserVersion()}`
      this.engine = detail.parsedResult.engine.name
      this.os = `${detail.parsedResult.os.name} ${detail.parsedResult.os.version}`
      this.platform = `${detail.parsedResult.platform.vendor} ${detail.parsedResult.platform.type}`
    }
  },
  created() {
    this.getBrowserInfo()
  },
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 100px 150px 100px 150px 100px 150px 100px 300px;
  justify-content: center;
  padding: 5px;
}
.header {
  background-color: rgba(0,0,0,0.15);
  font-size: small;
  font-weight: 800;
  display: flex;
  align-content:center;
  padding-left: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.15)
}
.content {
  background-color: rgba(0,0,0,0.05);
  font-size: small;
  font-weight: 400;
  display: flex;
  align-content: left;
  padding-left: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.1)
}
</style>