import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import CmsServices from '../services/CmsServices'
import TrainerServices from '../services/TrainerServices'

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    event: null,
    events: null,
    eventsList: null,
    trainer: null,
    trainers: null,
    user: null
  },
  mutations: {
    setEvent(state, event) {
      state.event = event
    },
    setEvents(state, events) {
      state.events = events
    },
    setEventsList(state, eventsList) {
      state.eventsList = eventsList
    },
    setTrainer(state, trainer) {
      state.trainer = trainer
    },
    setTrainers(state, trainers) {
      state.trainers = trainers
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData));
    },
    LOGOUT(state) {
      localStorage.clear()
      state.user = null
    }
  },
  actions: {
    fetchCMSEvents({ commit }) {
      CmsServices.getEvents()
      .then((response) => {
        commit('setEvents', response.data)
      })
    },
    fetchEvent({ commit }, guid) {
      CmsServices.getEventDetail(guid)
      .then((response) => {
      commit('setEvent', response.data)
      })
    },
    fetchCMSEventsList({ commit }) {
      CmsServices.getEventsList()
      .then((response) => {
        commit('setEventsList', response.data)
      })
    },
    fetchTrainer({ commit }, eventId) {
      TrainerServices.getTrainer(eventId)
      .then((response) => {
        commit('setTrainer', response.data)
      })
    },
    fetchCMSTrainers({ commit }) {
      TrainerServices.getTrainers()
      .then((response) => {
        commit('setTrainers', response.data)
      })
    },
    updateEvent(_, event ) {
      return CmsServices.updateEvent( event )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch('fetchCMSEventsList')
          store.dispatch('fetchCMSEvents')
          store.dispatch('fetchCMSTrainers')
          return "Event successfully updated!"
        } else {
          return `Error: ${response.data}`
        }
      })
      .catch((error) => {
        throw error.message
      })
    },
    login({ commit }, credentials) {
      commit('SET_USER_DATA', credentials)
    },
    logout({ commit }) {
      commit('LOGOUT')
    }
  }
})

export default store

